import { useMediaQuery } from "@mui/material";
import React from "react";

const useScreenSize = () => {
  const [vertical, setVertical] = React.useState<boolean>(window.innerHeight > window.innerWidth);

  const extraSmall = true;
  const small = useMediaQuery("(min-width: 600px)");
  const medium = useMediaQuery("(min-width: 960px)");
  const large = useMediaQuery("(min-width: 1280px)");
  const extraLarge = useMediaQuery("(min-width: 1536px)");

  React.useEffect(() => {
    const handleResize = () => {
      setVertical(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { extraSmall, small, medium, large, extraLarge, vertical };
};

export { useScreenSize };
